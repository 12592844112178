// const menuLinks = [
//   {
//     label: 'hi',
//     url: '/hi/'
//   },
//   {
//     label: 'blog',
//     url: '/blog/'
//   },
//   {
//     label: 'lab',
//     url: '/lab/'
//   }
// ]

const menuLinks = [
  {
    label: 'blog',
    url: '/blog/'
  }
]

export default menuLinks
