import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactGA from 'react-ga'

import * as S from './styled'

const trackClick = (item) => {
  ReactGA.event({
    category: 'Social',
    action: 'click',
    label: `Social - ${item}`
  })
}

const Social = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitterLink
              instagramLink
              githubLink
              facebookLink
            }
          }
        }
      }
    `
  )

  return (
    <S.Social>
      <S.SocialItem
        href={site.siteMetadata.social.facebookLink}
        title="Facebook"
        onClick={() => trackClick('Facebook')}>
        <S.SocialFacebook />
      </S.SocialItem>
      <S.SocialItem
        href={site.siteMetadata.social.twitterLink}
        title="Twitter"
        onClick={() => trackClick('Twitter')}>
        <S.SocialTwitter />
      </S.SocialItem>
      <S.SocialItem
        href={site.siteMetadata.social.instagramLink}
        title="Instagram"
        onClick={() => trackClick('Instagram')}>
        <S.SocialInstagram />
      </S.SocialItem>
      <S.SocialItem
        href={site.siteMetadata.social.githubLink}
        title="Github"
        onClick={() => trackClick('Github')} >
        <S.SocialGithub />
      </S.SocialItem>      
      {/* <S.SocialItem
        href={site.siteMetadata.social.tumblrLink}
        title="Tumblr"
        onClick={() => trackClick('Tumblr')} >
        <S.SocialTumblr />
      </S.SocialItem> */}

    </S.Social>
  )
}

export default Social
